<template>
    <PreparingPage></PreparingPage>
</template>

<script>
import PreparingPage from "@/components/preparing-page";
export default {
    name: "index",
    components: {
        PreparingPage
    }
}
</script>

<style scoped>

</style>