<template>
    <div class="card">
        <div class="card-body p-0">
            <div class="text-center px-5 mt-15">
                <img src="/images/unauthorized-access.png" alt="" class="mh-125px">
            </div>

            <div class="card-px text-center pb-20 my-10 mb-0">
                <h2 class="fs-2x fw-bolder mb-3">{{ $t('pages.preparingPage.title') }}</h2>
                <p class="text-gray-400 fs-5 fw-bold mb-13">{{ $t('pages.preparingPage.description') }}</p>

                <button type="button" class="btn btn-primary er fs-6 px-8 py-4 w-100 w-sm-auto" @click="$router.back();">
                    <span class="svg-icon svg-icon-2">
                        <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
                    </span>
                    {{ $t("btn.back") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index"
}
</script>

<style scoped>

</style>